import React, { MouseEvent } from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'

import styles from './tile-course.module.scss'

type Props = {
    active: boolean
    activeClass: string
    rightBorderClass: string
    heading: string
    description: string
    link: StoryblokLink
}

const CourseTile = ({ heading, description, link: { cached_url }, active, activeClass, rightBorderClass }: Props) => {
    const ref = React.createRef<HTMLLIElement>()
    const containerClass = cn(styles.card, active && activeClass)

    const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (!ref.current.classList.contains(activeClass)) {
            e.preventDefault()
        }
    }

    const handleClick = () =>
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    const to = `/${cached_url}`

    return (
        <li ref={ref} onClick={handleClick} className={containerClass}>
            <Link onClick={handleLinkClick} to={to}>
                <Heading className={styles.cardHeading} type={Types.H3} text={heading} />
                <Text className={styles.cardDescription}>{description}</Text>
                <span className={styles.readMore}>
                    Find out more <i className="icon icon-right"></i>
                </span>
                <span className={rightBorderClass}></span>
            </Link>
        </li>
    )
}

export default CourseTile
