import React from 'react'

import Heading, { Types } from '@components/atoms/heading/heading'
import TestimonialTile, { Props as Testimonial } from '@components/molecules/tile-testimonial/tile-testimonial'

import styles from './testimonials.module.scss'

type Props = {
    heading: string
    testimonials: Testimonial[]
}

const Testimonials = ({ heading, testimonials }: Props) => {
    return (
        <div className={styles.testimonials}>
            <Heading className={styles.header} text={heading} type={Types.H2} />
            <div className={styles.background}></div>
            <ul className={styles.tileList}>
                {testimonials.map(({ course, quote }, i) => (
                    <li key={`${quote}-${i}`}>
                        <TestimonialTile course={course} quote={quote} />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Testimonials
