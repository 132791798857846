import React, { useEffect, createRef, memo } from 'react'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'
import CourseTile from '@components/molecules/tile-course/tile-course'

import styles from './list-course.module.scss'

type CourseTile = {
    heading: string
    description: string
    link: StoryblokLink
}

type Props = {
    courses: CourseTile[]
    description: string
    title: string
}

const CourseList = memo(({ title, description, courses }: Props) => {
    const ref = createRef<HTMLUListElement>()
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (!ref.current) {
                return
            }
            const scrollTop = document.body.scrollTop + window.innerHeight / 2
            const positions = []

            const closest = (array, number) => {
                var num = 0
                for (var i = array.length - 1; i >= 0; i--) {
                    if (Math.abs(number - array[i].position) < Math.abs(number - array[num].position)) {
                        num = i
                    }
                }
                return array[num].element
            }

            const arr = Array.from(ref.current.children)
            const currentActive = arr.filter((node) => node.classList.contains(styles.active))[0]
            arr.forEach((node, i) => {
                positions.push({ position: node.getBoundingClientRect().top, element: node })
            })
            const getClosest = closest(positions, scrollTop)
            if (getClosest !== currentActive) {
                getClosest.classList.add(styles.active)
                currentActive.classList.remove(styles.active)
            }
        })
    }, [])

    return (
        !!courses.length && (
            <section className={styles.listCourse}>
                <div className={styles.info}>
                    <Heading className={styles.heading} text={title} type={Types.H2} />
                    <Text className={styles.description}>{description}</Text>
                </div>

                <div className={styles.wrapper}>
                    <div className={styles.start}>
                        <div className={styles.circleOuter}>
                            <div className={styles.circleInner}></div>
                        </div>
                    </div>

                    <ul ref={ref} className={styles.list}>
                        {courses.map(({ heading, description, link }, i) => (
                            <CourseTile
                                rightBorderClass={styles.rightBorder}
                                key={`${link}-${i}`}
                                link={link}
                                heading={heading}
                                description={description}
                                active={i === 0}
                                activeClass={styles.active}
                            />
                        ))}
                    </ul>
                    <div className={styles.end}>
                        <div className={styles.circleOuter}>
                            <div className={styles.circleInner}></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    )
})

export default CourseList
