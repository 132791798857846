import React from 'react'
import cn from 'classnames'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'

import styles from './tile-testimonial.module.scss'

export type Props = {
    course: string
    quote: string
}

const TestimonialTile = ({ course, quote }: Props) => {
    const star = <i className={cn('icon-star', styles.star)}></i>
    return (
        <div className={styles.tileTestimonial}>
            <div className={styles.header}>
                {star}
                {star}
                {star}
                {star}
                {star}
            </div>
            <Text>"{quote}"</Text>
            <Text className={styles.course}>{course}</Text>
        </div>
    )
}

export default TestimonialTile
