import React from 'react'
import { format, parse } from 'date-fns'

import Heading, { Types } from '@components/atoms/heading/heading'

import Text from '@components/atoms/text/text'
import styles from './tile-available-course.module.scss'

export type Props = {
    day: string
    date: string
    level: string
    link: string
    location?: string
    type: 'online' | 'classroom' | 'blended'
    name: string
    price: string
    warning?: string
}

const AvailableCourseTile = ({ date, level, link, location, type, name, price, warning }: Props) => {
    return (
        <a rel="nofollow" href={link} className={styles.card}>
            <Heading className={styles.cardHeading} type={Types.H3} text={name} />
            {level && (
                <Text className={styles.cardText}>
                    <strong>Level: </strong>
                    {level}
                </Text>
            )}
            {!!type && (
                <Text className={styles.cardText}>
                    <strong>Type: </strong>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                </Text>
            )}
            {location && (
                <Text className={styles.cardText}>
                    <i className="icon-location"></i>
                    {location}
                </Text>
            )}
            <footer className={styles.footer}>
                <Text className={styles.footerText}>
                    <i className="icon-pound"></i>
                    {price}
                </Text>
                {!!date && (
                    <Text className={styles.footerText}>
                        <i className="icon-calendar"></i>
                        {format(parse(date, 'yyyy-MM-dd HH:mm', new Date()), 'dd-LL-yyyy')}
                    </Text>
                )}
                {!!warning && (
                    <Text className={styles.warning}>
                        <i className="icon icon-attention"></i>
                        {warning}
                    </Text>
                )}
            </footer>
            <Text className={styles.bookNow}>
                Book Now <i className="icons icon-right"></i>
            </Text>
            <span className={styles.borderRight}></span>
        </a>
    )
}

export default AvailableCourseTile
