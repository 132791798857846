import Heading from '@components/atoms/heading/heading'
import TextBlock from '@components/molecules/block-text/block-text'

import AvailableCourseTile from '@components/molecules/tile-available-course/tile-available-course'
import CourseTile from '@components/molecules/tile-course/tile-course'
import TestimonialTile from './molecules/tile-testimonial/tile-testimonial'
import Image from '@components/molecules/image/image'

import PromotedTile from '@components/organisms/tile-promoted/tile-promoted'
import CourseList from '@components/organisms/list-course/list-course'
import Testimonials from '@components/organisms/testimonials/testimonials'
import Columns from '@components/organisms/columns/columns'

import Page from '@components/templates/page/page'
import CoursePage from '@components/templates/course/course'
import Home from '@components/templates/home/home'

const ComponentList = {
    availableCourse: AvailableCourseTile,
    columns: Columns,
    page: Page,
    heading: Heading,
    home: Home,
    image: Image,
    listCourse: CourseList,
    testimonials: Testimonials,
    text: TextBlock,
    tileCourse: CourseTile,
    tilePromoted: PromotedTile,
    tileTestimonial: TestimonialTile,
    coursePage: CoursePage,
    course: CoursePage,
    default: null
}

const Components = (type) => ComponentList[type]

export default Components
