import React from 'react'

import Components from '@components/components'
import Container from '../../../layouts/container/container'
import MetaData from '@components/atoms/metadata/metadata'
import Breadcrumbs from '@components/molecules/breadcrumbs/breadcrumbs'

const Page = (props) => {
    const breadcrumbs = [{ label: props.name }]

    return (
        <Container courses={props.courses}>
            <Breadcrumbs items={breadcrumbs} />
            <MetaData url={props?.location?.href} />
            {props.blok.body && props.blok.body.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, ...blok }))}
        </Container>
    )
}

export default Page
