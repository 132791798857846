import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'

import '../../../static/fonts/fontello/css/fontello.css'

import Heading, { Types } from '@components/atoms/heading/heading'
import Button, { Icons } from '@components/atoms/button/button'
import Metadata from '@components/atoms/metadata/metadata'
import Text from '@components/atoms/text/text'

import AvailableCourse, { Props as AvailableCourseProps } from '@components/molecules/tile-available-course/tile-available-course'
import Breadcrumbs from '@components/molecules/breadcrumbs/breadcrumbs'
import Container from '../../../layouts/container/container'
import Component from '@components/components'

import scrollTo from '@helpers/scrollTo'

import styles from './course.module.scss'

interface Props {
    blok: {
        availableCourses: AvailableCourseProps[]
        body: any
        summary: string
    }
    location: any
    name: string
    upcomingCourses: []
    courses: Course[]
}

const Page = ({ blok: { availableCourses, body, summary }, name, upcomingCourses, location, courses: footerCourses }: Props) => {
    const [courses, setCourses] = useState(upcomingCourses ?? [])
    const [showButton, setShowButton] = useState(true)

    const fetchCourses = async () => {
        const res = await fetch(
            `${process.env.GATSBY_STORYBLOK_API_URI}${process.env.GATSBY_STORYBLOK_PATH}?token=${
                process.env.GATSBY_STORYBLOK_PREVIEW
            }&by_slugs=upcoming-courses/*&cv=${new Date().getTime()}`
        )
        const data = await res.json()
        setCourses(data.stories)
    }

    const handleBookNow = () => {
        scrollTo('upcoming')
    }

    const onCoursesRender = (ref: HTMLDivElement) => {
        if (ref) {
            const intersectionObserver = new IntersectionObserver((entries) => {
                setShowButton(entries[0].intersectionRatio <= 0)
            })
            intersectionObserver.observe(ref)
        }
    }

    useEffect(() => {
        !courses.length && fetchCourses()
    }, [])

    const breadcrumbs = [{ label: 'Courses', link: '/courses' }, { label: name }]
    const iconClass = cn('icon', 'icon-empty', styles.emptyIcon)
    return (
        <Container courses={footerCourses} className={styles.pageContainer}>
            <Metadata url={location?.href} description={summary} title={`MW Training | ${name} `} />
            <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
            <section className={styles.content}>
                <Heading type={Types.H1} className={styles.name} text={name} />
                {body?.map(
                    (component) =>
                        component &&
                        React.createElement(Component(component.component), {
                            key: component._uid,
                            className: styles.body,
                            ...component
                        })
                )}
            </section>
            {!!availableCourses && !!courses && (
                <section ref={onCoursesRender} id="upcoming" className={styles.sidebar}>
                    <Heading className={styles.sidebarHeading} type={Types.H2} text="Upcoming Courses" />
                    {!!availableCourses.length && (
                        <ul className={styles.availableCourses}>
                            {availableCourses.map((uuid, i) => {
                                const course = courses.filter((course) => course.uuid === uuid)[0]
                                return (
                                    !!course && (
                                        <li key={i} className={styles.availableCourse}>
                                            <AvailableCourse {...course?.content} />
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    )}
                    {!availableCourses.length && (
                        <div className={styles.emptyContainer}>
                            <i className={iconClass}></i>
                            <Text className={styles.emptyText}>
                                We haven't got any courses available right now. If you're interested in this course{' '}
                                <Link to="/contact">get in touch</Link> and we may be able to help you!{' '}
                            </Text>
                        </div>
                    )}
                </section>
            )}
            {!!availableCourses?.length && showButton && (
                <Button
                    aria-label="Scroll to courses"
                    className={styles.bookNow}
                    text="Book Now"
                    icon={Icons.ArrowDown}
                    onClick={handleBookNow}
                />
            )}
        </Container>
    )
}

export default Page
