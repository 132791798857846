import React from 'react'
import cn from 'classnames'

import StoryblokClient from 'storyblok-js-client'

import styles from './block-text.module.scss'

type Props = {
    className?: string
    children: React.ReactNode
}

const TextBlock = ({ children, className }: Props) => {
    let Storyblok = new StoryblokClient({
        accessToken: process.env.STORYBLOK_TOKEN
    })

    function createMarkup(storyblokHTML) {
        return {
            __html: Storyblok.richTextResolver.render(storyblokHTML)
        }
    }

    const containerClass = cn(styles.blockText, className)

    return <div className={containerClass} dangerouslySetInnerHTML={createMarkup(children)} />
}

export default TextBlock
