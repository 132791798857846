import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import Components from '@components/components'
import Text from '@components/atoms/text/text'
import Metadata from '@components/atoms/metadata/metadata'
import Columns from '@components/organisms/columns/columns'

import Container from '../../../layouts/container/container'

import styles from './home.module.scss'

const Home = (props) => {
    const [hidden, setHidden] = useState(false)
    const [wordIndex, setWordIndex] = useState(0)
    const words = ['simple', 'engaging']
    const colors = ['#2a9d8f', '#e9c46a', '#f4a261']

    useEffect(() => {
        const interval = setInterval(() => {
            setHidden(true)
            setWordIndex((wordIndex) => (wordIndex === words.length - 1 ? 0 : wordIndex + 1))
            setHidden(false)
        }, 3500)
        return () => clearInterval(interval)
    }, [])

    const { location } = props

    return (
        <Container courses={props.courses}>
            <Metadata url={location?.href} />
            <h1 className={styles.heading}>
                We make learning first aid{' '}
                {!hidden && (
                    <motion.span exit={{ opacity: 0 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={styles.word}>
                        {words[wordIndex]}

                        <span className={styles.rule} style={{ backgroundColor: colors[wordIndex] }} />
                    </motion.span>
                )}
            </h1>
            <Text className={styles.text}>
                M.W Training deliver up to date and quality training in first aid to ensure your company or school meets its health and
                safety requirements to your employees.
            </Text>
            {props.blok.body && props.blok.body.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, ...blok }))}
        </Container>
    )
}

export default Home
