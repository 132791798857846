import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'

import Image, { Sizes } from '@components/molecules/image/image'

import styles from './tile-promoted.module.scss'

type Props = {
    backgroundColor: string
    image: Image
    heading: string
    subheading: string
    link: StoryblokLink
    description: string
}

const PromotedTile = ({ backgroundColor, image, heading, link: { cached_url }, description, subheading }: Props) => {
    const to = `/${cached_url}`
    const containerClass = cn(styles.promotedTile, styles[backgroundColor])

    return (
        <div className={containerClass}>
            <Heading className={styles.subheading} text={subheading} type={Types.H3} />
            <div className={styles.wrapper}>
                <div className={styles.textContainer}>
                    <Heading className={styles.heading} text={heading} type={Types.H2} />
                    <Text className={styles.description}>{description}</Text>
                    {!!cached_url && (
                        <Link className={styles.link} to={to}>
                            Find Out More <i className="icon icon-right"></i>
                        </Link>
                    )}
                </div>
                <div className={styles.imageContainer}>
                    <Image className={styles.image} image={image} size={Sizes.TilePromoted} />
                </div>
            </div>
        </div>
    )
}

export default PromotedTile
