import React from 'react'
import Components from '@components/components'

import styles from './columns.module.scss'

interface Props {
    columns: []
}

const Columns = ({ columns }: Props) => {
    return (
        <div className={styles.grid}>
            {columns?.map((column) => {
                return (
                    <div className={styles.gridItem}>
                        {React.createElement(Components(column.component), { key: column._uid, ...column })}
                    </div>
                )
            })}
        </div>
    )
}

export default Columns
